import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BioModalIPC from "./bioModalIPC";
import { StaticQuery, graphql } from "gatsby";

function IPC2022Panelists(props) {
  var sectionTitle = props.title
    ? props.title
    : "Panelists from the 2021 Convergence";

  return (
    <StaticQuery
      query={graphql`
        query IPC2022Bios {
          allMdx(
            filter: { frontmatter: { tags: { eq: "ipc_2022_event_speaker" } } }
            sort: { fields: frontmatter___biolink, order: ASC }
          ) {
            nodes {
              frontmatter {
                title
                headshot
                biolink
                lastname
              }
              body
            }
          }
        }
      `}
      render={(data) => (
        <Container fluid="lg" className="py-3 py-lg-5" id="panelists">
          <Row className="m-3 mb-md-3">
            <Col className="p-3 border-bottom text-center">
              <h2 className="speaker-title">{sectionTitle}</h2>
            </Col>
          </Row>
          <Row className="justify-content-xl-around m-0 my-md-3">
            {data.allMdx.nodes.map((node) => (
              <BioModalIPC
                BioTitle={node.frontmatter.title}
                BioThumb={node.frontmatter.headshot}
                BioText={node.body}
              ></BioModalIPC>
            ))}
          </Row>
          <Row className="justify-content-center">
            <Col className="p-3 text-center">
              <h3 className="mb-2">
                100+ panelists are expected at the 2022 Convergence.
              </h3>
              <p>Click on each photo to learn about each panelist.</p>
            </Col>
          </Row>
        </Container>
      )}
    />
  );
}

export default IPC2022Panelists;
